
import { defineComponent } from 'vue';
import { BasicScene } from '@/BabylonExample/BasicScene';

export default defineComponent({
  name: 'BabylonExamples',
  mounted(){
    const canvas = document.querySelector("canvas")!;
    new BasicScene(canvas);
  }

});
